import React from "react"
import PDF from "../assets/pdf/HookPoint_Deck.pdf"

export default function Download() {
  React.useEffect(() => {
    if (typeof document !== "undefined") {
      document.querySelector(".index_link").click()
    }
  }, [])
  return (
    <a
      className="index_link"
      href={PDF}
      download
      // ref=""
      target="_blank"
      without
      rel="noreferrer"
      onClick={e => {
        e.stopPropagation()
        if (typeof window !== "undefined") {
          setTimeout(() => {
            window.open("about:blank", "_self")
            window.close()
          }, 4000)
        }
      }}
    />
  )
}
